"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-49266902-737836/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _objectSpread2 = _interopRequireDefault(require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-49266902-737836/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.iterator.constructor.js");
require("core-js/modules/es.iterator.find.js");
require("core-js/modules/es.object.to-string.js");
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _elementUi = require("element-ui");
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _auth = require("@/utils/auth");
_nprogress.default.configure({
  showSpinner: false
});
var whiteList = ["/login", "/hlLogin", "/auth-redirect", "/bind", "/register", "/dragGrowthScreenshot"];

// 获取路由视频
var getRouterVideoUrl = function getRouterVideoUrl(to, routes) {
  var title = to.meta.title;
  var route = (routes || []).find(function (item) {
    return item.meta.title === title && item.name === name && !item.children;
  });
  _store.default.commit("SET_VIDEO_URL", route && route.introduceVideoUrl);
};

// 调整弹出层
var getRoutePer = function getRoutePer(to) {
  var title = to.meta.title;
  var name = to.name;
  var routes = _store.default.state.permission.sliceRoutes;
  getRouterVideoUrl(to, routes);
  var permissions = _store.default.state.user.permissions;
  var dialog = false;
  if (title === "首页" || title === "个人中心" || title === "任务中心" || permissions[0] === "*:*:*") return;
  var route = routes.find(function (item) {
    return item.meta.title === title && item.name === name && !item.children;
  });
  // isSynz： //菜单带锁展示 1:锁 2:不锁
  if (route && route.isSynz === 1) dialog = true;
  if (!route) dialog = true;

  // 2023/09/21 调整
  _store.default.commit("SET_DIALOG", dialog);
  return dialog;

  // if (!permissions && !Array.isArray(permissions) && permissions.length <= 0)
  //   dialog = true;
  // const per = permissions.find((item) => item === (route && route.perms));
  // if (!per) dialog = true;
  // store.commit("SET_DIALOG", dialog);
  // return dialog;
};
_router.default.beforeEach(function (to, from, next) {
  var title = to.meta.title || "";
  _nprogress.default.start();
  _store.default.commit("SET_DIALOG", false);
  if ((0, _auth.getToken)()) {
    /* has token*/
    if (to.path === "/login") {
      next({
        path: "/"
      });
      _nprogress.default.done();
    } else {
      if (_store.default.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch("GetInfo").then(function (res) {
          // 拉取user_info
          var roles = res.roles;
          // store.dispatch("getMenuList")
          _store.default.dispatch("GenerateRoutes", {
            roles: roles
          }).then(function (accessRoutes) {
            // 测试 默认静态页面
            // store.dispatch('permission/generateRoutes', { roles }).then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            _router.default.addRoutes(accessRoutes); // 动态添加可访问路由表
            next((0, _objectSpread2.default)((0, _objectSpread2.default)({}, to), {}, {
              replace: true
            })); // hack方法 确保addRoutes已完成
          });
          //拉去部门树
          _store.default.dispatch("GetDeptTree");
          getRoutePer(to);
        }).catch(function (err) {
          _store.default.dispatch("FedLogOut").then(function () {
            // Message.error(err);
            console.log(err, "err");
            next({
              path: "/"
            });
          });
        });
      } else {
        var dialog = getRoutePer(to);
        if (dialog && title.indexOf("大屏") !== -1) {
          confirm("\n            \u8BE5\u6A21\u5757\u6743\u9650\u6682\u672A\u5F00\u542F,\u6B22\u8FCE\u8054\u7CFB\u6211\u53F8\u7BA1\u7406\u5458\n            \u8054\u7CFB\u7535\u8BDD\uFF1A0571-58088906\n          ");
        } else {
          next();
        }
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        // if (hasPermission(store.getters.roles, to.meta.roles)) {
        //   next()
        // } else {
        //   next({ path: '/401', replace: true, query: { noGoBack: true }})
        // }
        // 可删 ↑
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      // 2024/07/28 托育需求迁移刀wen端冲突 代码找回
      next("/login?redirect=".concat(encodeURIComponent(to.fullPath))); // 否则全部重定向到登录页  decodeURIComponent()配合使用
      _nprogress.default.done();
    }
  }
});
_router.default.afterEach(function () {
  _nprogress.default.done();
});