"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.iterator.constructor.js");
require("core-js/modules/es.iterator.find.js");
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex");
var _Link = _interopRequireDefault(require("./components/Sidebar/Link.vue"));
var _draggable = _interopRequireDefault(require("./draggable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Layout",
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView,
    AppLink: _Link.default
  },
  mixins: [_ResizeHandler.default],
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    },
    dialog: function dialog(state) {
      return state.permission.dialog;
    },
    permission_routes: function permission_routes(state) {
      return state.permission.routes;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    },
    isChat: function isChat() {
      return this.permission_routes.find(function (item) {
        return item.name === "Chat" && !item.hidden;
      });
    }
  }),
  data: function data() {
    return {};
  },
  directives: {
    draggable: _draggable.default
  },
  methods: {
    draggableClick: function draggableClick() {
      this.$router.push("/chat/chat");
    }
  },
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {}
};