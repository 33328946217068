"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchedNurseryToken = matchedNurseryToken;
exports.nurseryTokens = void 0;
var _store = _interopRequireDefault(require("@/store"));
var nurseryTokens = exports.nurseryTokens = {
  nurseryTokenDev: "0ca3f3a69e7c4aecbd196f923134e699",
  //徐宁杰测试幼儿园token（用于测试）
  nurseryTokenProd: "fcc33d9f30914ef5a455d16a209c3e7d",
  //七彩幼儿园token（临时满足该幼儿园放大字体要求，后续需迭代）
  nurseryTokenForJiaaogongxue: "13a89a0f089d42fc83f98cd78b815a72",
  //乐清市佳澳公学幼儿园
  nurseryTokenForMengdefeier: "3c4ecf46b216463289365f17e1d601d6",
  // 测试
  nurseryTokenForXinguang: "f73b67b254274618a221d5aab4cc7994",
  //无锡市新光幼儿园数据大屏
  nurseryTokenForLiShui: "ced5304417914bf99c6738c00dd37cbf",
  // 新和县丽水幼儿园
  nurseryTokenForHaiLiang: "1bffa39f6de34dbbbf6d555557a745b1",
  //海亮
  nurseryTokenForGuoXin: "5b16d1656a5d49719aca20c4256dfb14",
  // 国信嘉园
  // 2024/07/28 托育需求迁移刀wen端冲突 代码找回
  nurseryTokenForJiaoZuo: "fc90dde3b5d949aa8a7eff2c8904cd7e" //新疆焦作幼儿园
};
function matchedNurseryToken(key) {
  switch (key) {
    case nurseryTokens.nurseryTokenDev:
      _store.default.commit("SET_CustomizedToken", true);
      break;
    case nurseryTokens.nurseryTokenProd:
      _store.default.commit("SET_CustomizedToken", true);
      break;
    case nurseryTokens.nurseryTokenForJiaaogongxue:
      _store.default.commit("SET_CustomizedTokenForJiaaogongxue", true);
      break;
    case nurseryTokens.nurseryTokenForMengdefeier:
      _store.default.commit("SET_CustomizedTokenForMengdefeier", true);
      break;
    case nurseryTokens.nurseryTokenForXinguang:
      _store.default.commit("SET_CustomizedTokenForXinguang", true);
      break;
    case nurseryTokens.nurseryTokenForLiShui:
      _store.default.commit("SET_CustomizedTokenForLiShui", true);
      break;
    case nurseryTokens.nurseryTokenForHaiLiang:
      _store.default.commit("SET_CustomizedTokenForHaiLiang", true);
      break;
    case nurseryTokens.nurseryTokenForGuoXin:
      _store.default.commit("SET_CustomizedTokenForGuoXin", true);
      break;
    case nurseryTokens.nurseryTokenForJiaoZuo:
      _store.default.commit("SET_CustomizedTokenForJiaoZuo", true);
      break;
    default:
      _store.default.commit("SET_CustomizedToken", false);
      _store.default.commit("SET_CustomizedTokenForJiaaogongxue", false);
      _store.default.commit("SET_CustomizedTokenForMengdefeier", false);
      _store.default.commit("SET_CustomizedTokenForXinguang", false);
      _store.default.commit("SET_CustomizedTokenForLiShui", false);
      _store.default.commit("SET_CustomizedTokenForHaiLiang", false);
      _store.default.commit("SET_CustomizedTokenForGuoXin", false);
  }
}