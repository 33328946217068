"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRouters = exports.getMenu = exports.USERACTIVITYLOG = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取路由
var getRouters = exports.getRouters = function getRouters() {
  return (0, _request.default)({
    url: '/getRouters',
    method: 'get'
  });
};
var getMenu = exports.getMenu = function getMenu() {
  return (0, _request.default)({
    url: '/system/menu/list',
    method: 'get'
  });
};

// 用户埋点接口
var USERACTIVITYLOG = exports.USERACTIVITYLOG = function USERACTIVITYLOG(data) {
  return (0, _request.default)({
    url: "/common/log/menu",
    data: data,
    method: "POST"
  });
};