"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InitResetPwd = InitResetPwd;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
exports.reqCheck = reqCheck;
exports.reqGet = reqGet;
var _objectSpread2 = _interopRequireDefault(require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
// 登录方法
function login(username, password, captchaVerification) {
  var data = {
    username: username,
    password: password,
    captchaVerification: captchaVerification
  };
  return (0, _request.default)({
    url: "/login2",
    method: "post",
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      type: "pc"
    })
  });
}

// 获取用户详细信息
function getInfo() {
  return (0, _request.default)({
    url: "/getInfo",
    method: "get"
  });
}

// 退出方法
function logout() {
  return (0, _request.default)({
    url: "/logout",
    method: "post"
  });
}

// 获取滑块验证图片
function reqGet(data) {
  return (0, _request.default)({
    url: "/system/captcha/get",
    method: "post",
    data: data
  });
}

// 滑块验证检查
function reqCheck(data) {
  return (0, _request.default)({
    url: "/system/captcha/check",
    method: "post",
    data: data
  });
}

// 初次登录强制修改密码
function InitResetPwd(data) {
  return (0, _request.default)({
    url: "/system/user/profile/resetPwd",
    method: "post",
    data: data
  });
}