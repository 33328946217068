"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-49266902-737836/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExportOrderList = ExportOrderList;
exports.OrderCreate = exports.OrderCancel = exports.GetProductModule = exports.GetProductList = void 0;
exports.OrderList = OrderList;
exports.OrderUpPay = exports.OrderState = exports.OrderPayComplete = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
// 创建订单
var OrderCreate = exports.OrderCreate = function OrderCreate(data) {
  return (0, _request.default)({
    url: "/order/create",
    method: "post",
    data: data
  });
};

// 查询订单状态
var OrderState = exports.OrderState = function OrderState(params) {
  return (0, _request.default)({
    url: "/order/queryStatus",
    method: "get",
    params: params
  });
};

// 取消订单
var OrderCancel = exports.OrderCancel = function OrderCancel(params) {
  return (0, _request.default)({
    url: "/order/cancel",
    method: "get",
    params: params
  });
};

// 支付完成
var OrderPayComplete = exports.OrderPayComplete = function OrderPayComplete(params) {
  return (0, _request.default)({
    url: "/order/payComplete",
    method: "get",
    params: params
  });
};

// 调起订单支付组件
var OrderUpPay = exports.OrderUpPay = function OrderUpPay(params) {
  return (0, _request.default)({
    url: "/order/callUpPayment",
    method: "get",
    params: params
  });
};
// 查询产品列表
var GetProductList = exports.GetProductList = function GetProductList(params) {
  return (0, _request.default)({
    url: "/product/list",
    method: "get",
    params: params
  });
};
// 查询产品模块列表
var GetProductModule = exports.GetProductModule = function GetProductModule(params) {
  return (0, _request.default)({
    url: "/productModule/list",
    method: "get",
    params: params
  });
};

// 查询订单列表
function OrderList(params) {
  return (0, _request.default)({
    url: "/order/list",
    method: "get",
    params: params
  });
}

// 订单列表导出
function ExportOrderList(params) {
  return (0, _request.default)({
    url: "/order/export",
    method: "get",
    params: params
  });
}