"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-49266902-737836/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题，ps:必须与后端getRouters接口中返回数据的name匹配上，不然会出现权限锁问题，具体逻辑去看permission中getRoutePer()方法
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path(.*)",
    component: function component(resolve) {
      return require(["@/views/redirect"], resolve);
    }
  }]
}, {
  path: "/login",
  component: function component(resolve) {
    return require(["@/views/login"], resolve);
  },
  hidden: true
}, {
  path: "/Agreement",
  component: function component(resolve) {
    return require(["@/views/Agreement"], resolve);
  },
  hidden: true
}, {
  path: "/404",
  component: function component(resolve) {
    return require(["@/views/error/404"], resolve);
  },
  hidden: true
}, {
  path: "/401",
  component: function component(resolve) {
    return require(["@/views/error/401"], resolve);
  },
  hidden: true
}, {
  path: "/hlLogin",
  component: function component(resolve) {
    return require(["@/views/hlLogin/index.vue"], resolve);
  },
  hidden: true
}, {
  path: "/AIObserveCut",
  component: function component(resolve) {
    return require(["@/views/childCare/AIObserve/cutImage.vue"], resolve);
  },
  hidden: true
}, {
  path: "",
  component: _layout.default,
  redirect: "index",
  children: [{
    path: "index",
    component: function component(resolve) {
      return require(["@/views/homePage/index"], resolve);
    },
    name: "首页",
    meta: {
      title: "首页",
      icon: "homeIcon",
      noCache: true,
      affix: true
    }
  }
  // {
  //   path:"/tinymce",
  //   component: (resolve) => require(['@/views/weeklyplan/tinymce'], resolve),
  //   name: 'tinymce',
  //   meta: { title: 'tinymce', icon: 'dashboard', noCache: true, affix: true }
  // },
  ]
}, {
  path: "/user",
  component: _layout.default,
  hidden: true,
  redirect: "noredirect",
  children: [{
    path: "profile",
    component: function component(resolve) {
      return require(["@/views/system/user/profile/index"], resolve);
    },
    name: "Profile",
    meta: {
      title: "个人中心",
      icon: "user"
    }
  }]
}, {
  path: "",
  component: _layout.default,
  hidden: true,
  redirect: "noredirect",
  children: [{
    path: "taskCenter",
    component: function component(resolve) {
      return require(["@/views/taskCenter/index.vue"], resolve);
    },
    name: "任务中心",
    meta: {
      title: "任务中心",
      icon: "user"
    }
  }]
}, {
  path: "/dragBanpai",
  component: function component(resolve) {
    return require(["@/views/drag/banpai/edit.vue"], resolve);
  },
  hidden: true
}, {
  path: "/dragLrgeScreen",
  component: function component(resolve) {
    return require(["@/views/drag/lrgeScreen/edit.vue"], resolve);
  },
  hidden: true
}, {
  path: "/showLrgeScreen",
  component: function component(resolve) {
    return require(["@/views/drag/showLrgeScreen/index.vue"], resolve);
  },
  hidden: true
}, {
  path: "/dragPropagandize",
  component: function component(resolve) {
    return require(["@/views/drag/propagandize/edit.vue"], resolve);
  },
  hidden: true
}, {
  path: "/showPropagandize",
  component: function component(resolve) {
    return require(["@/views/drag/propagandize/show.vue"], resolve);
  },
  hidden: true
}, {
  path: "/dragHonorCertificateEdit",
  component: function component(resolve) {
    return require(["@/views/drag/honorCertificate/edit.vue"], resolve);
  },
  hidden: true
}, {
  path: "/dragGrowthEdit",
  component: function component(resolve) {
    return require(["@/views/drag/growthRecord/contentEdit.vue"], resolve);
  },
  hidden: true
}, {
  path: "/dragGrowthFill",
  component: function component(resolve) {
    return require(["@/views/drag/growthRecord/contentFill.vue"], resolve);
  },
  hidden: true
}, {
  path: "/dragGrowthScreenshot",
  // 服务端截图页面
  component: function component(resolve) {
    return require(["@/views/drag/growthRecord/serverScreenshot.vue"], resolve);
  },
  hidden: true
}, {
  path: "/",
  component: _layout.default,
  hidden: true,
  redirect: "noredirect",
  children: [{
    path: "archives",
    component: function component(resolve) {
      return require(["@/views/archives"], resolve);
    },
    name: "Archives",
    meta: {
      title: "成长档案",
      icon: "dashboard",
      noCache: true,
      affix: true,
      notAdd: true
    }
  }, {
    path: "sign",
    component: function component(resolve) {
      return require(["@/views/sign"], resolve);
    },
    name: "Sign",
    meta: {
      title: "签名墙",
      icon: "dashboard",
      noCache: true,
      affix: true,
      notAdd: true
    }
  }
  // {
  //   path: "school/offcialAccount/management",
  //   component: (resolve) => require(["@/views/system/offcialAccount/management"], resolve),
  //   name: "offcialAccountManagement",
  //   meta: {
  //     title: "公众号管理",
  //     icon: "dashboard",
  //     noCache: true,
  //     affix: true,
  //     notAdd:true
  //   },
  // },
  ]
}, {
  path: "/dict",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "type/data/:dictId(\\d+)",
    component: function component(resolve) {
      return require(["@/views/system/dict/data"], resolve);
    },
    name: "Data",
    meta: {
      title: "字典数据",
      icon: ""
    }
  }]
}, {
  path: "/job",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "log",
    component: function component(resolve) {
      return require(["@/views/monitor/job/log"], resolve);
    },
    name: "JobLog",
    meta: {
      title: "调度日志"
    }
  }]
}, {
  path: "/gen",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "edit",
    component: function component(resolve) {
      return require(["@/views/tool/gen/editTable"], resolve);
    },
    name: "GenEdit",
    meta: {
      title: "修改生成配置"
    }
  }]
}
// {
//   path: '/screen',
//   component: (resolve) => require(['@/views/system/screen'], resolve),
//   hidden: true,
//   meta: { title: '大屏' }
// },
// {
//   path:"/weekplan",
//   component: Layout,
//   redirect: 'weekplan',
//   hidden: true,
//   children:[
//     {
//       path:"add",
//       component: (resolve) => require(['@/views/weeklyplan/tinymce'], resolve),
//       name: '添加周计划',
//       meta: { title: '添加周计划', icon: 'dashboard', noCache: true, affix: true }
//     }
//   ]
// }
];
var _default = exports.default = new _vueRouter.default({
  mode: "history",
  // 去掉url中的#
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});