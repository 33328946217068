"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-46869129-405880/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encryptByDES = exports.decryptDES = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
var _cryptoJs = _interopRequireDefault(require("crypto-js"));
var encryptByDES = exports.encryptByDES = function encryptByDES(message, key) {
  var keyHex = _cryptoJs.default.enc.Utf8.parse(key);
  var encrypted = _cryptoJs.default.DES.encrypt(message, keyHex, {
    mode: _cryptoJs.default.mode.ECB,
    padding: _cryptoJs.default.pad.Pkcs7
  });
  return encrypted.toString();
};
// 4、解密（originText：加密文本，key：密钥）
var decryptDES = exports.decryptDES = function decryptDES(ciphertext, key) {
  var keyHex = _cryptoJs.default.enc.Utf8.parse(key);
  if (ciphertext) {
    var decrypted = _cryptoJs.default.DES.decrypt(ciphertext, keyHex, {
      mode: _cryptoJs.default.mode.ECB,
      padding: _cryptoJs.default.pad.Pkcs7
    });
    return decrypted.toString(_cryptoJs.default.enc.Utf8);
  }
  return "";
};