"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _dynamicSetting = require("@/dynamicSetting");
// axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
_axios.default.defaults.headers = {
  "Content-Type": "application/json;charset=utf-8"
};
// 创建axios实例
var service = _axios.default.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: _dynamicSetting.apiUrl
  // baseURL: "https://datacenter.qiaozhihui.net/prod-api",
  // baseURL: "http://192.168.3.252:3000",
  // 超时
  // timeout: 60000,
});

// request拦截器
service.interceptors.request.use(function (config) {
  if ((0, _auth.getToken)()) {
    config.headers["Authorization"] = "Bearer " + (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // gpt请求特殊处理
  if (config.url === "/ai/chat/stream/completions") {
    config.headers["Accept"] = "text/event-stream";
  }
  return config;
}, function (error) {
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (res) {
  //如果响应式文件流 不做处理 下载文件接口
  var responseType = res.config.responseType === "blob";
  if (responseType) {
    return Promise.resolve(res);
  }

  // 直播状态正常
  _store.default.commit("app/TOGGLE_ISLIVE");
  var code = res.data.code;
  if (code === 401) {
    _elementUi.MessageBox.confirm("登录状态已过期，您可以继续留在该页面，或者重新登录", "系统提示", {
      confirmButtonText: "重新登录",
      cancelButtonText: "取消",
      type: "warning"
    }).then(function () {
      _store.default.dispatch("LogOut").then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
  } else if (code !== 200 && code !== 412) {
    _elementUi.Notification.error({
      title: "警告",
      message: res.data.msg
    });
    return Promise.reject(res.data);
  } else {
    return Promise.resolve(res.data);
  }
}, function (error) {
  _store.default.state.app.isLive = true;
  // alert("请检测网络是否连接");
  (0, _elementUi.Message)({
    message: "请检测网络是否连接",
    type: "error",
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;