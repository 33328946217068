"use strict";

var _interopRequireWildcard = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/core-js/modules/es.array.iterator.js");
require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/core-js/modules/es.promise.js");
require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/core-js/modules/es.object.assign.js");
require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/core-js/modules/es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _compositionApi = _interopRequireDefault(require("@vue/composition-api"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./assets/styles/element-variables.scss");
var _eventBus = _interopRequireDefault(require("@/utils/eventBus"));
require("@/assets/styles/index.scss");
require("@/assets/styles/ruoyi.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _permission = _interopRequireDefault(require("./directive/permission"));
var _dataView = _interopRequireDefault(require("@jiaminghi/data-view"));
var _echartsFontsize = _interopRequireDefault(require("@/utils/echartsFontsize"));
require("./assets/icons");
require("./permission");
var _data = require("@/api/system/dict/data");
var _config = require("@/api/system/config");
var _ruoyi2 = require("@/utils/ruoyi");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _moment = _interopRequireDefault(require("moment"));
var fundebug = _interopRequireWildcard(require("fundebug-javascript"));
var _fundebugVue = _interopRequireDefault(require("fundebug-vue"));
var _Icon = _interopRequireDefault(require("vue-awesome/components/Icon"));
require("vue-awesome/icons/chart-bar.js");
require("vue-awesome/icons/chart-area.js");
require("vue-awesome/icons/chart-pie.js");
require("vue-awesome/icons/chart-line.js");
require("vue-awesome/icons/align-left.js");
var echarts = _interopRequireWildcard(require("echarts"));
var _animate = _interopRequireDefault(require("animate.css"));
// a modern alternative to CSS resets

// global css
// ruoyi css

// icon
// permission control

// bug监测

fundebug.apikey = "f9dde1fccf2e0d652abff87395de605e54b93f521678af7d5b2b818591672b4f";
// 生产环境安装监控
if (process.env.NODE_ENV !== "development") {
  (0, _fundebugVue.default)(fundebug, _vue.default);
}
// 按需引入vue-awesome图标

//引入echart

//引入animate

_vue.default.prototype.$echarts = echarts;
_vue.default.prototype.$echartsFontsize = _echartsFontsize.default;
// 全局注册图标
_vue.default.component("icon", _Icon.default);

// 全局方法挂载
_vue.default.prototype.getDicts = _data.getDicts;
_vue.default.prototype.getConfigKey = _config.getConfigKey;
_vue.default.prototype.parseTime = _ruoyi2.parseTime;
_vue.default.prototype.resetForm = _ruoyi2.resetForm;
_vue.default.prototype.addDateRange = _ruoyi2.addDateRange;
_vue.default.prototype.selectDictLabel = _ruoyi2.selectDictLabel;
_vue.default.prototype.download = _ruoyi2.download;
_vue.default.prototype.handleTree = _ruoyi2.handleTree;
_vue.default.prototype.moment = _moment.default;
_vue.default.prototype.$eventBus = _eventBus.default;
//  oss前缀
var ossUrl = "https://qzhcdn.qiaozhihui.net/miniProgram/";
_vue.default.prototype.oss_url = ossUrl;
_vue.default.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
};
_vue.default.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error"
  });
};
_vue.default.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};

// 全局组件挂载
_vue.default.component("Pagination", _Pagination.default);
_vue.default.use(_permission.default);
_vue.default.use(_dataView.default);
_vue.default.use(_animate.default);
_vue.default.use(_compositionApi.default);
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get("size") || "medium" // set element-ui default size
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});

// select 选择器数据过多分页处理
_vue.default.directive("loadMore", {
  // 该指令绑定在 HTML 元素上时触发
  bind: function bind(el, binding) {
    // 获取滚动的下拉框
    var SCROLL_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
    var scrollPosition = 0;
    SCROLL_DOM.addEventListener("scroll", function () {
      // 当前的滚动位置 减去 上一次的滚动位置
      // 如果为true则代表向上滚动，false代表向下滚动
      var flag = this.scrollTop - scrollPosition > 0;
      // 记录当前的滚动位置
      scrollPosition = this.scrollTop;
      // 记录滚动位置距离底部的位置,在滚动位置距离滚动页面底部一定高度时再触发
      var LIMIT_HEIGHT = 10;
      var scrollBottom = this.scrollHeight - (this.scrollTop + this.clientHeight) < LIMIT_HEIGHT;
      var loadMore = binding.value.loadMore;
      // 如果已达到指定位置则触发
      // 如果向下滚动 并且距离底部只有10px
      if (flag && scrollBottom) {
        // 将滚动行为告诉组件
        loadMore(flag);
      }
    });
  }
});