import { render, staticRenderFns } from "./SidebarItem.vue?vue&type=template&id=2d2bbdc2"
import script from "./SidebarItem.vue?vue&type=script&lang=js"
export * from "./SidebarItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-46869129-405880/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d2bbdc2')) {
      api.createRecord('2d2bbdc2', component.options)
    } else {
      api.reload('2d2bbdc2', component.options)
    }
    module.hot.accept("./SidebarItem.vue?vue&type=template&id=2d2bbdc2", function () {
      api.rerender('2d2bbdc2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/Sidebar/SidebarItem.vue"
export default component.exports