"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-49266902-737836/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.number.constructor.js");
var _store = _interopRequireDefault(require("@/store"));
var _default = exports.default = {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    // 是否认证
    isSynz: {
      type: Number,
      default: 2
    },
    useLock: Boolean
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title,
      useLock = _context$props.useLock,
      isSynz = _context$props.isSynz;
    var permissions = _store.default.state.user.permissions;
    var vnodes = [];
    var isLock = function isLock() {
      if (title === "首页" || permissions[0] === "*:*:*") return false;
      if (!useLock) return false;
      //isSynz： 菜单带锁展示 1:锁 2:不锁
      return isSynz === 1;
      // if( !permissions && !Array.isArray(permissions) && permissions.length <= 0 ) return true
      // if( !perms ) return true
      // const per = permissions.find( item => item === perms )
      // if( !per ) return true
      // return false
    };
    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }
    if (title) {
      vnodes.push(h("span", {
        "slot": "title"
      }, [title, "\xA0 \xA0 \xA0", isLock() && h("i", {
        "class": "el-icon-lock"
      })]));
    }
    return vnodes;
  }
};