"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-46869129-405880/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetDeptByPage = GetDeptByPage;
exports.GetDeptByPageAll = GetDeptByPageAll;
exports.addDept = addDept;
exports.delDept = delDept;
exports.getDept = getDept;
exports.listDept = listDept;
exports.onListDept = onListDept;
exports.queryDeptByType = queryDeptByType;
exports.roleDeptTreeselect = roleDeptTreeselect;
exports.treeselect = treeselect;
exports.updateDept = updateDept;
var _request = _interopRequireDefault(require("@/utils/request"));
//
/** 根据部门类型查询部门
 *
 * @param {String} params.deptType    部门类型："1"集团园  "2"校区  "3"班级
 * @returns {Promise<T>}
 */
function queryDeptByType(params) {
  return (0, _request.default)({
    url: "/system/dept/campusDeptTree",
    method: "get",
    params: params
  });
}

// 查询部门列表
function listDept(query) {
  return (0, _request.default)({
    url: "/system/screen/Screelist",
    method: "get",
    params: query
  });
}
//也是查询部门列表 目的为了配合后端做权限管理，上面的接口多处被使用，所以重开一个接口
function onListDept(query) {
  return (0, _request.default)({
    url: "/system/dept/treeselectHasPermi",
    method: "get",
    params: query
  });
}
// 分页查询部门列表（admin账号卡顿，园所组织管理所以用这个）
function GetDeptByPage(query) {
  return (0, _request.default)({
    url: "/system/dept/treeselectHasPermi/page",
    method: "get",
    params: query
  });
}
// 分页查询部门列表，用户管理左侧部门树,部门名称查询支持补全父节点
function GetDeptByPageAll(query) {
  return (0, _request.default)({
    url: "/system/dept/tree/search",
    method: "get",
    params: query
  });
}
// 查询部门详细
function getDept(deptId) {
  return (0, _request.default)({
    url: "/system/dept/" + deptId,
    method: "get"
  });
}

// 查询部门下拉树结构
function treeselect(params) {
  return (0, _request.default)({
    url: "/system/dept/treeselect",
    method: "get",
    params: params
  });
}

// 根据角色ID查询部门树结构
function roleDeptTreeselect(roleId) {
  return (0, _request.default)({
    url: "/system/dept/roleDeptTreeselect/" + roleId,
    method: "get"
  });
}

// 新增部门
function addDept(data) {
  return (0, _request.default)({
    url: "/system/dept",
    method: "post",
    data: data
  });
}

// 修改部门
function updateDept(data) {
  return (0, _request.default)({
    url: "/system/dept",
    method: "put",
    data: data
  });
}

// 删除部门
function delDept(deptId) {
  return (0, _request.default)({
    url: "/system/dept/" + deptId,
    method: "delete"
  });
}