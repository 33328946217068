import { render, staticRenderFns } from "./index.vue?vue&type=template&id=40bd2e60"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40bd2e60')) {
      api.createRecord('40bd2e60', component.options)
    } else {
      api.reload('40bd2e60', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=40bd2e60", function () {
      api.rerender('40bd2e60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SizeSelect/index.vue"
export default component.exports