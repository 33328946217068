"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-50694530-501821/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _logo = _interopRequireDefault(require("@/assets/logo/logo.png"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    nurseryLogo: function nurseryLogo(state) {
      return state.user.nurserylogo;
    } //使用ES6的箭头函数来给count赋值
  })),
  data: function data() {
    return {
      title: "数据中心管理系统",
      logo: _logo.default
    };
  }
};