"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-47770097-948476/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.delData = delData;
exports.exportData = exportData;
exports.getData = getData;
exports.getDicts = getDicts;
exports.listData = listData;
exports.updateData = updateData;
var _request = _interopRequireDefault(require("@/utils/request"));
// 查询字典数据列表
function listData(query) {
  return (0, _request.default)({
    url: '/system/dict/data/list',
    method: 'get',
    params: query
  });
}

// 查询字典数据详细
function getData(dictCode) {
  return (0, _request.default)({
    url: '/system/dict/data/' + dictCode,
    method: 'get'
  });
}

// 根据字典类型查询字典数据信息
function getDicts(dictType) {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/' + dictType,
    method: 'get'
  });
}

// 新增字典数据
function addData(data) {
  return (0, _request.default)({
    url: '/system/dict/data',
    method: 'post',
    data: data
  });
}

// 修改字典数据
function updateData(data) {
  return (0, _request.default)({
    url: '/system/dict/data',
    method: 'put',
    data: data
  });
}

// 删除字典数据
function delData(dictCode) {
  return (0, _request.default)({
    url: '/system/dict/data/' + dictCode,
    method: 'delete'
  });
}

// 导出字典数据
function exportData(query) {
  return (0, _request.default)({
    url: '/system/dict/data/export',
    method: 'get',
    params: query
  });
}